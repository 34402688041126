import { always, equals, cond, T } from 'ramda';

export const chartTitleGenerator = cond([
  [equals('studentCount'), always('Aantal leerlingen')],
  [equals('relativeReserve'), always('Relatieve reserve per leerling')],
  [equals('participationRate'), always('Deelnamepercentage')],
  [
    equals('participationRate-vso - bekostigd'),
    always('Deelnamepercentage (vso - bekostigd)'),
  ],
  [equals('participationRate-lwoo'), always('Deelnamepercentage (lwoo)')],
  [equals('participationRate-pro'), always('Deelnamepercentage (pro)')],
  [equals('participationRate-sbo'), always('Deelnamepercentage (sbo)')],
  [
    equals('participationRate-so - bekostigd'),
    always('Deelnamepercentage (so - bekostigd)'),
  ],
  [equals('absence'), always('Aantal leerlingen')],
  [equals('equity'), always('Eigen vermogen')],
  [equals('reservePosition'), always('Huidige reservepositie')],
  [equals('signalingValue'), always('Signaleringswaarde')],
  [equals('supervision'), always('Toezicht op het beleid')],
  [equals('policy'), always('Beleid')],
  [T, always('No title configured')],
]);

export default chartTitleGenerator;
