import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Section from '@greenberry/salal/components/Section';
import Introduction from '@greenberry/salal/components/Introduction';

import ComparisonLayout from '../../components/ComparisonLayout';
import TableIndicator from '../../components/Comparison/TableIndicator';

const ComparisonOrganization = ({ data: { copy } }) => (
  <ComparisonLayout seoMetaTags={copy.seoMetaTags}>
    <Section size="2">
      <Introduction title={copy.title} text={copy.intro} />
    </Section>
    <TableIndicator
      title={copy.policyTitle}
      body={copy.policyBody}
      indicatorName="policy"
      visibleKeys={['organizationForm', 'executiveResponsibilities']}
    />

    <TableIndicator
      title={copy.supervisionTitle}
      body={copy.supervisionBody}
      indicatorName="supervision"
      visibleKeys={['supervisoryIndependence', 'supervisoryDuties']}
    />
  </ComparisonLayout>
);

ComparisonOrganization.propTypes = {
  data: PropTypes.shape({
    copy: PropTypes.shape({
      title: PropTypes.string.isRequired,
      intro: PropTypes.string.isRequired,
      policyTitle: PropTypes.string.isRequired,
      policyBody: PropTypes.string.isRequired,
      supervisionTitle: PropTypes.string.isRequired,
      supervisionBody: PropTypes.string.isRequired,
      inspectionTitle: PropTypes.string.isRequired,
      inspectionBody: PropTypes.string.isRequired,
      seoMetaTags: PropTypes.shape({
        tags: PropTypes.array,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ComparisonOrganization;

export const query = graphql`
  query getOrganisation {
    copy: datoCmsComparisonOrganization {
      title
      intro
      policyTitle
      policyBody
      supervisionTitle
      supervisionBody
      inspectionTitle
      inspectionBody
      seoMetaTags {
        tags
      }
    }
  }
`;
