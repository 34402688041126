import React from 'react';
import IconChart from '@greenberry/salal/components/Charts/Icon';
import organizationTypeMap from '../../../constants/organizationTypeMap';
import labelMap from '../../../constants/datoLabelMap';

export const formatIndicator = (partnerships, visibleKeys) => {
  const data = visibleKeys.map(label => ({
    label: labelMap[label],
    values: partnerships.map(({ name, indicators }) => {
      const icon = organizationTypeMap[label].icons[indicators[label]];
      const color = organizationTypeMap[label].colors[indicators[label]];
      return {
        label: name,
        value: (
          <IconChart
            style={{
              color,
              flex: '0 1 auto',
            }}
            data={[
              {
                appearance: color,
                icon,
                name: indicators[label],
                circleSize: '60px',
                iconSize: 'lg',
              },
            ]}
          />
        ),
      };
    }),
  }));

  return data;
};
