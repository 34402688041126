import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValueLoadable } from 'recoil';
import TableChart from '@greenberry/salal/components/Charts/Table';
import { withIndicators } from '../../../state/selectedForComparison';
import IndicatorSection, { OVERVIEW } from '../../IndicatorSection';
import IndicatorLoading from '../../IndicatorLoading';
import { formatIndicator } from './utils';

const TableIndicator = ({ indicatorName, visibleKeys, ...props }) => {
  const partnerships = useRecoilValueLoadable(withIndicators);
  if (partnerships.state === 'loading')
    return (
      <IndicatorLoading indicator={indicatorName} message="Laden…" {...props} />
    );
  if (partnerships.contents.length === 0)
    return (
      <IndicatorLoading
        indicator={indicatorName}
        message="Geen samenwerkingsverbanden geselecteerd"
        {...props}
      />
    );
  const formattedIndicator = formatIndicator(
    partnerships.contents,
    visibleKeys
  );

  return (
    <>
      <IndicatorSection
        selectedView={OVERVIEW}
        indicator={indicatorName}
        disableViewToggle
        {...props}
      >
        <TableChart data={formattedIndicator} />
      </IndicatorSection>
    </>
  );
};

TableIndicator.propTypes = {
  indicatorName: PropTypes.string.isRequired,
  visibleKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TableIndicator;
